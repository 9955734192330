<template>
  <div>
    <b-modal
      id="create-material"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="`${$t('SubmitYourMaterialVariantfor')} `"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <div
          class="existingClient"
          style="display:flex; flex-direction:row; width: 46%; justify-content: space-between;"
        >
          <b-button
            v-b-toggle.collapse-345
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <b-form-group
            style="width: 80%; padding-bottom: 10px;"
            :label="$t('SelectExistingClient')"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchClients == null"
                  class="search1"
                  style=" position: absolute; left: 5px; top: 10px"
                />
                <b-icon-x-circle
                  v-else-if="clientId != '00000000-0000-0000-0000-000000000000'"
                  class="search1"
                  style="position: absolute; margin-top: -6px; right: 165px; top: 17px; cursor: pointer"
                  @click="searchClients = null ,client= false, resetCustomerData()"
                />
                <input
                  v-model="searchClients"
                  style="width:250px;"
                  autocomplete="off"
                  type="text"
                  name="search-box"
                  :placeholder="$t('ClientName')"
                  :style="getFirstNames.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getFirstNames.length > 0 && noSearch"
                ref="scrollContainer"
                class="dropdrop"
                :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getFirstNames"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByOn(result.clientId); noSearch = false"
                >
                  <p style="margin: 0">
                    {{ result.nameToDisplay }}
                  </p>
                </div>
              </div>
            </div>
          </b-form-group>
          <b-button
            v-if="client == false && clientId == '00000000-0000-0000-0000-000000000000'"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="width: 290px; height: 40px; margin-top: 27px;"
            @click="addNewClient"
          >
            {{ $t(`Addclient`) }}
          </b-button>
        </div>

        <section
          v-if="client == true || clientId != '00000000-0000-0000-0000-000000000000'"
          class="custom-modal__wrapper"
          style="border-width: 3px"
        >
          <b-tabs>
            <b-tab
              :title="$t('PersonalInfo')"
              active
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="$t('Salutation')">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.salutation.$model"
                      :options="salutation"
                      required
                      :placeholder="$t('SelectSalutation')"
                      :state="validateState('salutation')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.salutation == null && buttonSubmit == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('FirstName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.firstName.$model"
                      :state="validateState('firstName')"
                      placeholder="e.g. Michael"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('LastName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.lastName.$model"
                      :state="validateState('lastName')"
                      placeholder="e.g. Goodman"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="hello">

                  <b-form-group :label="`${$t('Gender')}`">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.gender.$model"
                      :options="[$t('Male'), $t('Female')]"
                      :placeholder="$t('SelectGender')"
                      required
                      :state="validateState('gender')"
                      aria-describedby="input-5-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.gender == null && buttonSubmit == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('Email')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.email.$model"
                      :state="validateState('email')"
                      placeholder="e.g. example@digitbusiness.ch"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{
                        $t('Must_be_email_format')
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('PhoneNumber')}`">
                    <vue-phone-number-input
                      v-model="staticForm.ordererDto.telephoneNumber"
                      :default-country-code="staticForm.ordererDto.countryCode"
                      :preferred-countries="['CH', 'DE', 'AL']"
                    />
                  </b-form-group>
                </div>

                <b-form-group :label="`${$t('OrderType')}`">
                  <b-form-select
                    v-model="selectedOrderType"
                    :class="{ f: error.oType != '' }"
                    dropup
                    :options="transformOrderTypes"
                    required
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        width: 67%;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                  <p
                    v-if="!selectedOrderType && buttonSubmit == true"
                    style="color: red"
                  >
                    {{ $t('This_is_a_required_field') }}
                  </p>
                </b-form-group>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('BillingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="`${$t('Street')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.street"
                      placeholder="e.g. Rexhep Maja"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('HouseNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.houseNumber"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="e.g. 30"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback"> {{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('DoorNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.doorNumber"
                      type="number"
                      placeholder="e.g. 50"
                      step="any"
                      min="0"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="hello">

                  <b-form-group :label="`${$t('PostalCode')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.postalCode"
                      min="0"
                      placeholder="e.g. 10000"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('City')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.city"
                      placeholder="e.g. Prishtinë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('Country')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.country"
                      placeholder="e.g. Kosovë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('ShippingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div>
                  <div
                    class="hello"
                    style="padding-top: 20px"
                  >
                    <b-form-group :label="`${$t('Street')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.street"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Rexhep Maja"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback>{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('HouseNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.houseNumber"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 30"
                        type="number"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('DoorNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.doorNumber"
                        :disabled="pickUpInStore"
                        type="number"
                        placeholder="e.g. 50"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">

                    <b-form-group :label="`${$t('PostalCode')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.postalCode"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 10000"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('City')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.city"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Prishtinë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('Country')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.country"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Kosovë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <span
                    class="duplicate-material"
                    @click="duplicateBillingAddress(), resetPickUpInStore()"
                  >
                    {{ $t('SameAsBillingAddress') }}
                  </span>
                </div>
                <p>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="pickUpInStore"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    @input="resetShippingAddress()"
                  >
                    {{ $t('GetInStore') }}
                  </b-form-checkbox>
                </p>
              </b-collapse>
            </b-tab>
          </b-tabs>
        </section>
      </b-form>
      <b-form style="margin-top: 20px;">
        <section
          class="custom-modal__wrapper"
          style="border-width: 3px;display: flex;flex-direction: column"
        >
          <div class="">
            <b-button
              v-b-toggle="`lapse-`"
              class="m-1"
              variant="light"
            >
              <b-icon-arrows-expand />
            </b-button>
            <span style="font-weight: 500">{{ $t('MaterialVariants') }}</span>
            <p
              v-if="dress[0].duplicateMV && buttonSubmit == true"
              style="color: red; margin: 0;"
            >
              {{ $t('DuplicateMaterial') }}
            </p>

            <b-collapse
              :id="`lapse-`"
              visible
            >
              <div
                class="modal-card__wrapper"
                style="align-items: center"
              >
                <div
                  v-for="(item, index2) in dress[0].formItem"
                  :key="index2"
                  class="modal-card"
                >
                  <p
                    v-if="item.isEmpty && buttonSubmit == true"
                    style="color: red; margin: 0;"
                  >
                    {{ $t('EmptyMaterial') }}
                  </p>
                  <b-form-group
                    id="input-group-3"
                    :label="`${$t('SelectMaterialType')}:`"
                    style="width: 100%;"
                  >
                    <i
                      class="modal-card__remove"
                      @click="removeMaterialItemFromCD(index2)"
                    >
                      <b-icon-trash />
                    </i>
                    <div
                      class="flex radio-form"
                      style="justify-content: space-between;"
                    >
                      <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                          @click="resetMeter(item)"
                        >
                          <input
                            v-model="item.measurementType"
                            type="radio"
                            value="Meter"
                          >
                          <label :for="`one${index2}`">{{ $t('Meter') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                          @click="resetPackage(item)"
                        >
                          <input
                            v-model="item.measurementType"
                            type="radio"
                            value="Package"
                          >
                          <label :for="`one${index2}`">{{ $t('Package') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                          @click="resetSet(item)"
                        >
                          <input
                            v-model="item.measurementType"
                            type="radio"
                            value="Set"
                          >
                          <label :for="`one${index2}`">{{ $t('Set') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                          @click="resetPair(item)"
                        >
                          <input
                            v-model="item.measurementType"
                            type="radio"
                            value="Pair"
                          >
                          <label :for="`one${index2}`">{{ $t('Pair') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                          @click="resetVerse(item)"
                        >
                          <input
                            v-model="item.measurementType"
                            type="radio"
                            value="Verse"
                          >
                          <label :for="`one${index2}`">{{ $t('Beaded strand') }}</label>
                        </div>
                        <div
                          :id="`two${index2}`"
                          class="flex aic"
                          @click="resetPart(item)"
                        >
                          <input
                            v-model="item.measurementType"
                            type="radio"
                            value="Part"
                          >
                          <label :for="`two${index2}`">  {{ $t('Part') }}</label>
                        </div>
                      </div>
                      <td>
                        <img
                          v-if="item.name != null && item.name != '' "
                          v-b-tooltip="$t('ClickToViewPhoto')"
                          style="height: 30px; border-radius: 6px;cursor: pointer "
                          src="../../assets/images/imageicon.svg"
                          alt=""
                          @click.stop="getAllImages(item.name.materialVariantId)"
                        >
                      </td>
                    </div>
                  </b-form-group>
                  <div class="search-toggle">

                    <p>{{ $t('SearchBy') }}</p>
                    <div>
                      <p
                        :class="{ active2: toggleSearch === 'MaterialName' }"
                        @click="showMaterialName(), toggleSearch = 'MaterialName'"
                      >
                        {{ $t('Name') }}
                      </p>
                      <p
                        :class="{ active2: toggleSearch === 'Description' }"
                        @click="showDescription(), toggleSearch = 'Description'"
                      >
                        {{ $t('Description') }}
                      </p>
                    </div>
                  </div>
                  <b-form-group
                    id="input-select-1"
                    label-for="select-1"
                  >
                    <vue-select
                      v-if="item.measurementType === 'Meter' && toggleSearch === 'Description'"
                      v-model="item.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                      :placeholder="$t('Description')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchMDesc"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageM == 1"
                          @click="prevMeter"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                          @click="nextMeter"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                      v-model="item.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchM"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageM == 1"
                          @click="prevMeter"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                          @click="nextMeter"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                      v-model="item.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPack"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePack == 1"
                          @click="prevPackage"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                          @click="nextPackage"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Package' && toggleSearch === 'Description'"
                      v-model="item.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                      :placeholder="$t('Description')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPackDesc"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePack == 1"
                          @click="prevPackage"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                          @click="nextPackage"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                      v-model="item.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchSet"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageSet == 1"
                          @click="prevSet"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                          @click="nextSet"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Set' && toggleSearch === 'Description'"
                      v-model="item.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                      :placeholder="$t('Description')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchSetDesc"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageSet == 1"
                          @click="prevSet"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                          @click="nextSet"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                      v-model="item.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPair"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePair == 1"
                          @click="prevPair"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                          @click="nextPair"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Pair' && toggleSearch === 'Description'"
                      v-model="item.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPairDesc"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePair == 1"
                          @click="prevPair"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                          @click="nextPair"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                      v-model="item.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchVerse"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageVerse == 1"
                          @click="prevVerse"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                          @click="nextVerse"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Verse' && toggleSearch === 'Description'"
                      v-model="item.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchVerseDesc"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageVerse == 1"
                          @click="prevVerse"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                          @click="nextVerse"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Part' && toggleSearch === 'MaterialName'"
                      v-model="item.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchP"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageP == 1"
                          @click="prevPart"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          type="button"
                          :style="getButtonStylePart"
                          :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                          @click="nextPart"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="item.measurementType === 'Part' && toggleSearch === 'Description'"
                      v-model="item.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPDesc"
                      @input="currentStock(item.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageP == 1"
                          @click="prevPart"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          type="button"
                          :style="getButtonStylePart"
                          :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                          @click="nextPart"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                  </b-form-group>
                  <b-form-group
                    v-if="item.measurementType === 'Meter'"
                    :label="`${$t('Meter')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.length"
                      type="number"
                      @input="valid(item,index)"
                    />
                    <p
                      v-if="item.length < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Package'"
                    :label="`${$t('Meter')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.length"
                      type="number"
                      @input="valid(item,index)"
                    />
                    <p
                      v-if="item.length < 0.0001 "
                      style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Set'"
                    :label="`${$t('Meter')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.length"
                      type="number"
                      @input="valid(item,index)"
                    />
                    <p
                      v-if="item.length < 0.0001 "
                      style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Pair'"
                    :label="`${$t('Meter')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.length"
                      type="number"
                      @input="valid(item,index)"
                    />
                    <p
                      v-if="item.length < 0.0001 "
                      style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Verse'"
                    :label="`${$t('Meter')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.length"
                      type="number"
                      @input="valid(item,index)"
                    />
                    <p
                      v-if="item.length < 0.0001 "
                      style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else
                    :label="`${$t('Part')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.quantity"
                      type="number"
                      @input="valid(item,index)"
                    />
                    <p
                      v-if="item.quantity < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-if="item.name"
                    :label="`${$t('Real Price')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.name.sellPrice"
                      type="number"
                      disabled
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.measurementType === 'Meter' && item.name"
                    :label="`${$t('Price with disocunt')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.name.priceWithDiscount"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
                    </p>
                    <p
                      v-if="item.name.priceWithDiscount < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Part' && item.name"
                    :label="`${$t('Price with disocunt')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.name.priceWithDiscount"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.quantity)}€` : null }}
                    </p>
                    <p
                      v-if="item.name.priceWithDiscount < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Package' && item.name"
                    :label="`${$t('Price with disocunt')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.name.priceWithDiscount"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
                    </p>
                    <p
                      v-if="item.name.priceWithDiscount < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Set' && item.name"
                    :label="`${$t('Price with disocunt')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.name.priceWithDiscount"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
                    </p>
                    <p
                      v-if="item.name.priceWithDiscount < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Pair' && item.name"
                    :label="`${$t('Price with disocunt')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.name.priceWithDiscount"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
                    </p>
                    <p
                      v-if="item.name.priceWithDiscount < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.measurementType === 'Verse' && item.name"
                    :label="`${$t('Price with disocunt')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="item.name.priceWithDiscount"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
                    </p>
                    <p
                      v-if="item.name.priceWithDiscount < 0.0001 "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-if="item.name ? item.name.materialVariantId === clickedMaterialVariantId : false"
                    :label="`${$t('Stock')}:`"
                    style="width: 100%;"
                  >
                    <b-form-input
                      v-model="getSavedStock.stockValue"
                      type="number"
                      disabled
                    />
                  </b-form-group>
                </div>
                <b-button
                  type="button"
                  size="sm"
                  class="buttonSubmit"
                  style="margin: 20px 0;"
                  @click="addNewRowForCD()"
                >
                  <p style="margin: 0">
                    + {{ $t('AddMaterial') }}
                  </p>
                </b-button>
              </div>
            </b-collapse>
          </div>
          <div class="custom-modal-wrapper">
            <p
              v-if="totalCost != 0.00"
              style="font-weight: bold;"
            >
              {{ $t('TotalCost') }}: {{ totalCost }}€
            </p>
          </div>
        </section>
      </b-form>
      <b-form>
        <section
          class="custom-modal__wrapper"
          style="border-width: 3px;display: flex;flex-direction: column;margin-top: 15px;"
        >
          <div style="display: flex;flex-direction:column; width: 97%; border: 1px solid lightgray; border-radius: 5px">
            <div style="display:flex;width:100%">
              <div style="display: flex; width: 28%; flex-direction: column;">
                <b-form-group
                  :label="`${$t('Price')}:`"
                  style="display: inline-block; background: white ; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 100%;"
                >
                  <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction: column;">

                    <div style="display: flex;">
                      <b-input
                        v-model.number="totalCost"
                        type="number"
                        disabled
                        style="width: 90%; margin-right: 5px;"
                      />
                      <b-form-select
                        v-model="currency "
                        dropup
                        required
                        :options="currencies"
                        aria-placeholder="Select order type"
                        aria-describedby="input-1-live-feedback"
                        style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                      />
                    </div>
                  </div>
                  <p
                    v-if="discountType == '%' && (currency != '$' && currency != '€' && currency != 'CHF') "
                    style="margin-bottom:0px"
                  >
                    {{ $t('SelectValute') }}
                  </p>
                </b-form-group>
                <b-form-group
                  :label="$t('PaidPrice')"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px; width: 100%;"
                >
                  <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction:column; width: 100%">
                    <div style="display:flex;">
                      <b-input
                        v-model.number="paidPrice"
                        type="number"
                        style="width: 90%; margin-right: 5px;"
                      />
                      <b-form-select
                        v-model="currency "
                        dropup
                        required
                        :options="currencies"
                        aria-placeholder="Select order type"
                        aria-describedby="input-1-live-feedback"
                        style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                      />
                    </div>
                  </div>
                  <p
                    v-if="discountType == '%' && (currency != '$' && currency != '€' && currency != 'CHF') "
                    style="margin-bottom:0px"
                  >
                    {{ $t('SelectValute') }}
                  </p>
                </b-form-group>
              </div>
              <div style="display: flex; flex-direction: row; margin-left:0px; justify-content:flex-start; height: 1%;">
                <b-button
                  v-if="!showDiscountForm"
                  type="button"
                  size="sm"
                  class="button"
                  style="margin: 20px 20px 20px 0px; width: 280px; margin-top: 16.5%"
                  @click="showDiscountForm = true"
                >
                  <p style="margin: 0">
                    + {{ $t('AddDiscount') }}
                  </p>
                </b-button>
                <b-form-group
                  v-if="showDiscountForm"
                  :label="`${$t('Discount')}:`"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; margin-right: 5px;margin-left: 10px;"
                >
                  <div style="display: flex;justify-content: flex-start; align-items: center;">
                    <b-input
                      v-model.number="discount"
                      type="number"
                      default="0"
                      style="margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="discountType"
                      style="margin-right: 5px;height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
                    >
                      <option value="$">
                        $
                      </option>
                      <option value="€">
                        €
                      </option>
                      <option value="CHF">
                        CHF
                      </option>
                      <option value="%">
                        %
                      </option>
                    </b-form-select>
                    <div>
                      <b-icon-trash
                        type="button"
                        size="sm"
                        style="background:white;color: black; height: 36px; width: 30px; padding: 5px; border-radius: 0.25rem;"
                        @click="showDiscountForm = false,resetDiscountedPrice() "
                      />
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  v-if="showDiscountForm"
                  :label="`${$t('PriceDiscount')}:`"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px;"
                >
                  <div style="display:flex">
                    <b-input
                      v-model.number="discountedPrice"
                      type="number"
                      :disabled="discountType == '%'"
                      style="margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="currency"
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                  <p
                    v-if="discountType == '%' && (currency != '$' && currency != '€' && currency != 'CHF') "
                    style="margin-bottom:0px; border: 1px solid #EAECF0"
                  >
                    {{ $t('SelectValute') }}
                  </p>
                </b-form-group>
              </div>
            </div>
            <div>
              <div style="margin-top:10px;margin-left: 10px ;display: flex">
                <p class="p-0 m-0 ml-50">
                  {{ $t('IncludeTax') }}
                  <toggle-button
                    :value="includeTax"
                    :sync="true"
                    color="#242F6E"
                    @change="includeTax = !includeTax"
                  />
                </p>
                <div
                  v-if="includeTax == true"
                  style=" margin-left: 10px; margin-bottom: 10px"
                >
                  <span>18%</span>
                </div>
              </div>
              <div style="margin-top:10px;margin-left: 10px;margin-bottom:10px;display: flex;gap: 5px">
                <span>{{ $t('AssignTeam') }}</span>
                <p class="p-0 m-0 ml-50">
                  <toggle-button
                    :value="assignTeam"
                    :sync="true"
                    color="#242F6E"
                    @change="assignTeam = !assignTeam"
                  />
                </p>,
                <span style="color:red">{{ $t('AttentionIf') }}</span>
              </div>
            </div>
            <div style="margin:10px">
              <div style="display:flex;gap:10px">
                <b-form-group
                  :label="$t('AddAccount')"
                >
                  <vue-select
                    v-model="accountId"
                    :options="getAccountDetails"
                    :reduce="(e) => e.accountId"
                    label="name"
                    :placeholder="$t('Select')"
                    style="width: 100%;"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('SelectSalesPerson')"
                >
                  <vue-select
                    v-model="roleId"
                    :options="getUsersMultipleRoles"
                    required
                    label="fullName"
                    :reduce="(account) => account.userID"
                    :placeholder="$t('SelectSalesPerson')"
                    aria-describedby="input-1-live-feedback"
                    style="width: 100%;"
                  />
                </b-form-group>
              </div>
              <div style="display:flex;gap:10px;">
                <b-form-group
                  :label="$t('InoviceRemark')"
                  style="width:100%;padding-top:5px;"
                >
                  <b-form-textarea
                    v-model="invoiceRemark"
                    :placeholder="$t('Notes')"
                    style="width:100%"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('SellDate')"
                  style="width:100%;padding-top:5px;"
                >
                  <date-picker
                    v-model="sellDate"
                    :placeholder="$t('SelectD')"
                    style="width:100%"
                    type="date"
                    format="YYYY-MM-DD"
                    value-type="format"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px;width:97%;border:1px solid lightgray;border-radius: 6px;padding:15px">
            <b-form-group
              :label="$t('ShippingCost')"
              style="width:30%"
            >
              <div
                style="display:flex;flex-direction:column;gap:5px;"
              >
                <div style="display:flex;flex-direction:row;gap:5px;">
                  <b-form-input
                    v-model="shippings.cost"
                    :placeholder="$t('Cost')"
                    type="number"
                  />
                  <b-form-select
                    v-model="shippings.currency"
                    dropup
                    :options="[{ value: null, text: 'None' }, ...currencies]"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
                <p
                v-if="((shippings.cost === '' || shippings.cost < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.paidAmount !== ''))"
                style="color:red"
              >{{ $t('FillData') }}</p>
                <b-form-group
                  :label="$t('paidAmount')"
                  style="width: 100%"
                />
                <b-form-input
                  v-model="shippings.paidAmount"
                  :placeholder="$t('paidAmount')"
                  type="number"
                />
                <p
                v-if="((shippings.paidAmount === '' || shippings.paidAmount < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.cost !== '' || shippings.cost < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
                <b-form-group
                  :label="$t('Notes')"
                  style="width:100%"
                >
                  <b-form-textarea
                    v-model="shippings.notes"
                    style="width:100%"
                    :placeholder="$t('Select')"
                  />
                </b-form-group>
                <p
                v-if="(shippings.notes === '' && (shippings.accountId !== null || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
              </div>
            </b-form-group>
            <b-form-group
              :label="$t('SelectAccount')"
              style="margin-top:5px;margin-bottom:5px;width:30%"
            >
              <vue-select
                v-model="shippings.accountId"
                :options="getAccountDetails"
                required
                label="name"
                :reduce="(account) => account.accountId"
                :placeholder="$t('SelectAccount')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <p
              v-if="(shippings.accountId == null && (shippings.notes !== '' || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
              style="color:red"
            >{{ $t('FillData') }}</p>
          </div>
        </section>
      </b-form>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-top: 20px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          <!-- <div
            v-if="loading"
            class="spinner-border spinner-border-sm"
          /> -->
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { client } from '@/domainConfig';
import VueEasyLightbox from 'vue-easy-lightbox'


export default {
  name: 'Order',
  components: {
    VueEasyLightbox,
    DatePicker,
  },
  mixins: [validationMixin],
  props: ['dress'],
  data() {
    return {
      assignTeam: false,
      toggleSearch: 'MaterialName',
      clickedMaterialVariantId: null,
      savedStockValue: 0,
      buttonSubmit: false,
      accountId: null,
      shippings: {
        cost: '',
        currency: null,
        accountId: null,
        notes: '',
        paidAmount: '',
      },
      roleId: null,
      invoiceRemark: null,
      sellDate: null,
      paidPrice: 0,
      includeTax: false,
      currency: '€',
      currencies: ['€', '$', 'CHF'],
      discountType: '€',
      showDiscountForm: false,
      discount: 0,
      paidAmount: 0,
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      error: {
        oType: '',
        duplicateError: '',
      },
      pageSize: 15,
      selectedOrderType: null,
      isSubmitClicked: false,
      showMeasurements: false,
      noSearch: false,
      arrowCounter: 0,
      enterPressed: false,
      clientId: '00000000-0000-0000-0000-000000000000',
      client: true,
      clienti: client.clientName,
      searchClients: null,
      pickUpInStore: false,
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      staticForm: {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastName: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
    }
  },
  validations: {
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        email: {
          email,
        },
        gender: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getClients', 'getSavedStock',
      'getOrderTypes', 'getMaterialsVByPackage', 'getMaterialsVBySet', 'getMaterialsVByVerse', 'getMaterialsVByPair',
      'getMeasermentsById',
      'getAccountDetails', 'getUsersMultipleRoles',
      'getFirstNames', 'getTotalItemsForClients', 'getCurrentPageForMVM', 'getCurrentPageForMVP',
      'getMaterialVariantColorNameLike', 'getMaterialsVByMeter', 'getMaterialsVByPart', 'getTotalItemsPages',
      'getTotalPagesForMVM', 'getTotalPagesForMVP', 'getImagesProduct', 'getClient', 'getLanguage',
      'getAccountDetails', 'getFilterVariantsByCodeLike']),
    transformOrderTypes() {
      const allOption = { value: null, text: `${this.$t('SelectOrderType')}`, orderType: 'null' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: order.orderType,
          orderType: order.orderType,
        }
      });
      return [allOption, ...orderTypes];
    },
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    totalCost() {
    // Initialize a variable to store the total cost
      let total = 0;

      // Iterate through each item in dress.formItem
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.dress[0].formItem) {
        if (item.name && item.name.priceWithDiscount) {
        // Check if the item has a name (material variant selected)
          if (item.measurementType === 'Meter') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Package') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Set') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Pair') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Verse') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else {
          // For items measured in Part, calculate the total cost
            total += item.name.priceWithDiscount * item.quantity;
          }
        } else if (item.name && !item.name.priceWithDiscount) {
          if (item.measurementType === 'Meter') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Package') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Set') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Pair') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Verse') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else {
          // For items measured in Part, calculate the total cost
            total += item.name.sellPrice * item.quantity;
          }
        }
      }

      // Return the total cost with 2 decimal places and in the correct format
      return `${total.toFixed(2)}`;
    },
    discountedPrice: {
      get() {
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          return this.totalCost - this.discount;
        } if (this.discountType === '%') {
          if (this.totalCost === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.discount / 100) * this.totalCost;
          return this.totalCost - percentageDiscount;
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          this.discount = this.totalCost - value;
        } else if (this.discountType === '%') {
          if (this.totalCost === 0) {
            this.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.totalCost - value) / (this.totalCost * 100);
            this.discount = percentageDiscount;
          }
        }
      },
    },
  },
  watch: {
    includeTax(newIncludeTax) {
      this.taxRate = newIncludeTax ? 18 : 0;
    },
    'shippings.cost': function (newCost) {
      if (newCost) {
        this.shippings.paidAmount = 0;
        this.shippings.currency = '€'
      }
      if (newCost < 0) {
        this.shippings.cost = 0
      }
    },
    'shippings.paidAmount': function (newCost) {
      if (newCost > this.shippings.cost) {
        this.shippings.paidAmount = 0;
      }
      if (newCost < 0) {
        this.shippings.paidAmount = 0
      }
    },
    discountType(value) {
      if (value === '$' || value === '€' || value === 'CHF') {
        this.currency = value
      } else if (value === '%') {
        this.currency = '€'
      }
      this.discount = 0
      this.$emit('discount-type', value)
    },
    // eslint-disable-next-line func-names
    currency(newCurrency) {
      if ((newCurrency === '$' || newCurrency === '€' || newCurrency === 'CHF') && this.discountType != '%') {
        this.discountType = newCurrency
      }
    },
    discountedPrice(newDiscountedPrice) {
      if (newDiscountedPrice > this.totalCost) {
        this.totalCost = newDiscountedPrice;
        this.discount = 0;
      }
    },
    totalCost(newPrice) {
      if (newPrice < this.discount) {
        this.discount = 0;
      }
    },
    // eslint-disable-next-line func-names
    discount(newDiscount) {
      if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
        if (newDiscount > this.totalCost && newDiscount > this.discountedPrice) {
          this.discount = 0;
        }
      } else if (this.discountType === '%') {
        if (newDiscount > 100) {
          this.discount = 0;
        }
      }
    },
    paidPrice(value) {
      if (value > this.discountedPrice) {
        this.paidPrice = 0
      }
    },
    dress: {
      handler(newValue) {
        const materialNames = newValue[0].formItem.map((item) => { return item.name ? item.name.materialVariantName : item.name });
        const uniqueNames = new Set(materialNames);
        newValue[0].duplicateMV = uniqueNames.size !== materialNames.length;

        newValue[0].formItem.forEach((item) => {
          item.isEmpty = item.name == null || item.name == '';
        });
        if (newValue[0].formItem.length == 0) {
          newValue[0].isEmpty = true;
        } else {
          newValue[0].isEmpty = newValue[0].formItem.some((item) => { return item.isEmpty });
        }
      },
      deep: true,
    },
    searchClients(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null) {
          this.clearCustomMeasurements();
          this.removeLists();
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.firstNames(value);
          this.noSearch = true
        }
      }, 500);
    },
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
      }
    },
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
      }
    },
  },
  methods: {
    ...mapActions([
      'resetCustomOrders', 'currentStockByVariant', 'filterVariantByColor', 'resetAllFiltered',
      'getImagesByMaterialVariantId', 'clearCustomMeasurements', 'getMaterialVariantsToMDCPagination',
      'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames',
      'resetSearchClients', 'loadMeasermentsById', 'filterVariantByCode',
    ]),
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    resetDiscountedPrice() {
      this.discountedPrice = this.totalCost;
      this.discount = 0
    },
    valid(item) {
      console.log(item)
      if (item.measurementType == 'Meter') {
        item.quantity = 0
        if (item.length < 0.001) {
          this.dress.showLengthError = true;
        } else {
          this.dress.showLengthError = false;
        }
      } else if (item.measurementType == 'Part') {
        item.length = 0
        if (item.quantity < 0.001) {
          this.dress.showLengthError = true;
        } else {
          this.dress.showLengthError = false;
        }
      }
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    totalPriceCalc(priceWithDiscount, length) {
      return (priceWithDiscount * length).toFixed(2);
    },
    async getAllImages(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        // this.changeLoadingtoTrue(false)

        await this.getImagesByMaterialVariantId(item).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesProduct.length; i++) {
        const image = this.getImagesProduct[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          materialVariantPhotoId: image.materialVariantPhotoId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    addNewRowForCD() {
      this.dress[0].formItem.push({
        materialVariantId: '',
        measurementType: 'Meter',
        name: '',
        length: 0,
        quantity: 0,
        price: 0,
        isEmpty: false,
      });
    },
    async getMVbyMeter(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
    removeMaterialItemFromCD(index) {
      this.dress[0].formItem.splice(index, 1)
    },
    resetMeter(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPackage(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },
    resetPart(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.staticForm.shippingAddressDto.street = this.staticForm.billingAddressDto.street;
      this.staticForm.shippingAddressDto.houseNumber = this.staticForm.billingAddressDto.houseNumber;
      this.staticForm.shippingAddressDto.doorNumber = this.staticForm.billingAddressDto.doorNumber;
      this.staticForm.shippingAddressDto.postalCode = this.staticForm.billingAddressDto.postalCode;
      this.staticForm.shippingAddressDto.city = this.staticForm.billingAddressDto.city;
      this.staticForm.shippingAddressDto.country = this.staticForm.billingAddressDto.country;
    },
    resetShippingAddress() {
      this.$v.staticForm.shippingAddressDto.$reset()
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    addNewClient() {
      this.client = true;
    },
    resetCustomerData() {
      this.staticForm.ordererDto.salutation = null;
      this.staticForm.ordererDto.firstName = '';
      this.staticForm.ordererDto.lastName = '';
      this.staticForm.ordererDto.email = '';
      this.staticForm.ordererDto.gender = null;
      this.staticForm.ordererDto.telephoneNumber = '';

      this.staticForm.billingAddressDto.street = '';
      this.staticForm.billingAddressDto.houseNumber = '';
      this.staticForm.billingAddressDto.doorNumber = '';
      this.staticForm.billingAddressDto.postalCode = '';
      this.staticForm.billingAddressDto.city = '';
      this.staticForm.billingAddressDto.country = '';

      this.staticForm.shippingAddressDto.street = '';
      this.staticForm.shippingAddressDto.houseNumber = '';
      this.staticForm.shippingAddressDto.doorNumber = '';
      this.staticForm.shippingAddressDto.postalCode = '';
      this.staticForm.shippingAddressDto.city = '';
      this.staticForm.shippingAddressDto.country = '';

      this.pickUpInStore = false;
      this.selectedOrderType = null;
      this.isPhoneNumberValid = null;
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },
    async searchByOn(value) {
      this.noSearch = false
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.staticForm.ordererDto.salutation = clientData.salutation
      this.staticForm.ordererDto.firstName = clientData.firstName
      this.staticForm.ordererDto.lastName = clientData.lastName
      this.staticForm.ordererDto.gender = clientData.gender
      this.staticForm.ordererDto.email = clientData.email
      this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
      this.staticForm.ordererDto.countryCode = clientData.countryCode
      this.staticForm.ordererDto.taxRate = clientData.taxRate
      this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
      this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
      this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
      this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
      this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
      this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
      this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
      this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
      this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
      this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
      this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
      this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
      this.body = clientData
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.$v.staticForm.ordererDto.$touch();
      if (this.$v.staticForm.ordererDto.$anyError) {
        return;
      }
      try {
        this.buttonSubmit = true;
        const materialVariantDtos = this.dress[0].formItem.map((item) => {
          return {
            materialVariantId: item.name.materialVariantId,
            measurementType: item.measurementType,
            name: item.name.materialVariantName,
            length: item.length,
            quantity: item.quantity,
            pricePerMeter: parseFloat(item.name.pricePerMeter),
            sellPrice: parseFloat(item.name.sellPrice),
            pricePerMeterWithDiscount: parseFloat(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice),
          };
        });

        const priceAndAccount = {
          price: this.totalCost,
          currency: this.currency,
          paidAmount: this.paidPrice,
          discount: this.discount,
          taxRate: this.taxRate,
          orderType: this.selectedOrderType,
          clientId: this.clientId,
          priceNotes: null,
          accountId: this.accountId,
          salesUserId: this.roleId,
        };

        // Check for shippings validity
        if (
          (this.shippings.cost < 0 && (this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.accountId !== null && (this.shippings.cost === '' || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.currency !== null && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.notes !== '' && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.paidAmount === ''))
      || (this.shippings.paidAmount < 0 && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === ''))
        ) {
          return;
        }

        if (
          this.shippings.cost === ''
      && this.shippings.accountId === null
      && this.shippings.currency === null
      && this.shippings.notes === ''
      && this.shippings.paidAmount === ''
        ) {
          this.shippings.isEmpty = true;
        } else {
          this.shippings.isEmpty = false;
        }

        const objekti = {
          ordererDto: this.staticForm.ordererDto,
          billingAddressDto: this.staticForm.billingAddressDto,
          shippingAddressDto: this.staticForm.shippingAddressDto,
          materialVariantDtos,
          ...priceAndAccount,
          isEmpty: this.dress[0].isEmpty,
          duplicateMV: this.dress[0].duplicateMV,
          assignTeam: this.assignTeam,
          shippingCostRequestDto: this.shippings.isEmpty ? null : this.shippings,
          invoiceRemark: this.invoiceRemark,
          sellDate: this.sellDate,
        };

        if (this.dress[0].isEmpty || this.dress[0].duplicateMV || this.selectedOrderType == null) {
          return;
        }

        this.$emit('addOrderNominative', objekti);
        this.$refs.modal.hide();
        this.resetCustomerData();
        this.resetData();
        setTimeout(() => {
          this.$v.$reset();
        }, 0);
      } catch (error) {
        console.error(error);
      }
    },
    onCancel() {
      this.resetCustomerData()
      this.resetData()
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
    resetData() {
      this.dress[0].formItem = [];
      this.discount = 0;
      this.showDiscountForm = false;
      this.paidPrice = 0;
      this.accountId = null;
      this.buttonSubmit = false;
      this.roleId = null;
      this.assignTeam = false;
      this.includeTax = false;

      if (this.shippings !== null) {
        this.shippings.cost = '';
        this.shippings.currency = null;
        this.shippings.accountId = null;
        this.shippings.notes = '';
        this.shippings.paidAmount = '';
      }

      this.invoiceRemark = null;
      this.sellDate = null
    },

  },
}
</script>

<style lang="scss" scoped>
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}

.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}


.btn-con {
  display: flex;

  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}


@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 65% !important;
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      color: $digit-white;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }

}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

</style>
