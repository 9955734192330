<template>
  <div style="margin-top:15px;">
    <table class="team_table">
      <thead>
        <tr>
          <th style="text-align:left;">
            {{ $t("VariantNew") }}
          </th>
          <th style="text-align:left;">
            {{ $t("Code") }}
          </th>
          <th style="text-align:left;">
            {{ $t("Image") }}
          </th>
          <th style="text-align:left;">
            {{ $t("Color") }}
          </th>
          <th style="text-align:left;">
            {{ $t("Price") }}
          </th>
          <th style="text-align:left;">
            {{ $t("Amount") }}
          </th>
          <th style="text-align:left;">
            {{ $t("MainSupplier") }}
          </th>
          <th style="text-align:left;">
            {{ $t("Location") }}
          </th>
          <th style="text-align:left;">
            {{ $t('TotalP') }}
          </th>
          <th style="text-align:left;">
            {{ $t('AddToOrder') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item,index) in getMaterialVariant"
          :key="index"
        >
          <td> {{ item.materialVariantName }}</td>
          <td> {{ item.description }}</td>
          <td
            v-if="item.photos[0]"
            style="display:flex;gap:3px;"
          >
            <template v-if="item.photos[0].endsWith('.pdf') || item.photos[0].endsWith('.docx') || item.photos[0].endsWith('.xlsm') || item.photos[0].endsWith('.xlsx') || item.photos[0].endsWith('.plain')">
              <img
                v-b-tooltip="$t('Document')"
                style="width:30px;height:30px"
                src="../../assets/images/documentLogo.svg"
              >
              <button
                v-if="item.photos.length > 0"
                v-b-tooltip="$t('Download')"
                style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                @click.stop="downloadFile(item.photos[0])"
              >
                <b-icon-download style="color: #FF274F;" />
              </button>
            </template>
            <template v-else>
              <img
                v-b-tooltip="$t('ClickToViewPhoto')"
                style="width:30px;height: 30px; border-radius: 3px; "
                :src="item.photos[0]"
                alt="No image"
                @click.stop="getAllImages(item)"
              >
              <button
                v-if="item.photos.length > 0"
                v-b-tooltip="$t('Download')"
                style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                @click.stop="downloadFile(item.photos[0])"
              >
                <b-icon-download style="color: #FF274F;" />
              </button>
            </template>

          </td>
          <td v-else>
            <img
              v-b-tooltip="$t('NoImageOrDocument')"
              style="width:30px;height:30px"
              src="../../assets/images/noImageAvailable.svg"
            >
          </td>
          <td> {{ item.colorName }}</td>
          <td> {{ numbersWithcomma(item.pricePerMeter) }} €</td>
          <td> {{ item.currentAmount }}</td>
          <td> {{ item.supplierName }}</td>
          <td> {{ item.locationName }}</td>
          <td> {{ numbersWithcomma(getTotalPriceByMaterialId) }} €</td>
          <td
            v-b-modal.material-to-standard
            style="cursor:pointer"
            @click="callModal(item)"
          >
            <button
              style="width:150px"
              class="button"
            >
              {{ $t('AddToOrder') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <b-pagination
        v-if="getMatVarTotalItems > 15"
        v-model="page"
        :total-rows="getMatVarTotalItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        size="md"
        prev-class="prev-itemii"
        next-class="next-itemii"
        class="mt-1 mb-0"
        style="z-index:1"
      >
        <template #prev-text>
          <span>{{ $t("Previous") }}</span>
        </template>
        <template #next-text>
          <span>{{ $t("Next") }}</span>
        </template>
      </b-pagination>
    </div>
    <AddToOrderStandard
      :material-variant-name-add="materialVariantNameAdd"
      @onSubmit="addToOrder"
    />
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
import AddToOrderStandard from './AddToOrderStandard.vue'

export default {
  name: 'Materials',
  components: {
    AddToOrderStandard,
    VueEasyLightbox,
  },
  props: ['measurementType', 'materialId'],
  data() {
    return {
      materialVariantNameAdd: null,
      page: 1,
      pageSize: 15,
      image: {
        zoom: 1,
        imgs: [],
        visible: false,
        index: 0, //
      },
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser',
      'getMaterialVariantPDFQRCode',
      'getInventoryMaterialVariantsId',
      'getMaterialColors',
      'getTotalPriceByMaterialId',
      'getIsInCart',
      'getMaterialVariantById',
      'getMaterialVariantHistoryCurrentPage',
      'getMaterialVariantHistoryTotalItems',
      'getMaterialVariantHistory',
      'getLanguage',
      'getMaterialVariantNames',
      'getMaterialVariant',
      'getMatVarTotalItems',
      'getMatVarCurrentPage',
      'getImagesProduct',
      'getFilterDescription',
    ]),
  },
  watch: {
    page(value) {
      if (value) {
        this.loadMaterialVariant({
          id: this.materialId,
          pageNumber: value,
          pageSize: this.pageSize,
          successCallback: () => {
            // this.changeLoadingtoTrue(true);
          },
        });
      }
    },
  },
  methods: {
    ...mapActions(['loadMaterialVariant']),
    async getAllImages(item) {
      // Set the array to an empty state at the beginning
      this.image.imgs = [];

      // Filter images based on the materialVariantId
      const filteredImages = this.getMaterialVariant.filter((image) => {
        return image.materialVariantId === item.materialVariantId;
      });
      // Fetch and push the filtered images
      for (let i = 0; i < filteredImages.length; i++) {
        const image = filteredImages[i];
        try {
          // Access all photos in the 'image.photos' array and push them to 'this.image.imgs'
          // eslint-disable-next-line no-restricted-syntax
          for (const photo of image.photos) {
            this.image.imgs.push({
              src: photo, // Assign the fetched image
              // materialVariantPhotoId: image.materialVariantPhotoId,
              // fileName: image.fileName,
              // fileType: image.fileType,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }

      // Continue with your other logic
      this.show();
    },
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    callModal(value) {
      this.materialVariantNameAdd = value
    },
    addToOrder(value) {
      let quantity = 0
      let length = 0
      if (this.measurementType == 'Part') {
        quantity = value.amount
      } else {
        length = value.amount
      }
      const name = {
        materialVariantId: value.materialVariantId,
        materialVariantName: value.materialVariantName,
        pricePerMeter: value.price,
        priceWithDiscount: value.priceWithDiscount,
      }
      const objekti = {
        materialVariantId: '',
        measurementType: this.measurementType,
        name,
        quantity,
        length,
        price: 0,
      }
      this.$emit('objectMaterial', objekti);
    },
  },
}

</script>

<style>
.team_table td {
  padding: 12px 9px !important;
}

.team_table th {
  /* text-align: center; */
}
</style>
